import { Component, useState } from "react";
import firebase from "../firebase/base";
import defaultBadge from "../asset/badge.gif";
import tbadge from "../asset/tbadge.png";
import sign from "../asset/sign.png";
import html2pdf from "html2pdf.js"
import { ajaxPrefilter } from "jquery";

const Header= ()=>{

    let db = firebase.firestore();
    

    const [BadgeNo, setBadge] = useState("");
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    

    const [show, setShow] = useState(false);

  
  

    const VerifyBadge=(e)=>{
        e.preventDefault();

        // alert("please wait")

        db.collection("Verify-Badge-No")
            .doc(BadgeNo)
            .get()
            .then(doc => {
            const data = doc.data();
            if(doc.exists){

                setMessage('Badge Number '+BadgeNo+' is validated');
                    setTimeout(function(){ 
                        setMessage('');
                    }, 2000);

                    setShow(!show);
                let a =document.getElementById("Download");
                // a.href="https://server.cwipedia.in/DATA/Internship/"+BadgeNo+".pdf";
                // document.getElementById("getName").innerHTML=data.Name;
                // document.getElementById("award").innerHTML="Awarded on the "+data.date;
                // document.getElementById("badgenum").innerHTML="Badge No: "+BadgeNo;
                // document.getElementById("role").innerHTML="for successfully completing the Externship program in "+data.role+" also, recognized the "+data.award+" contribution Award";
                // document.getElementById("learn").innerHTML=data.learn;


                document.getElementById("name").innerHTML="Name: "+data.Name;
                document.getElementById("role").innerHTML="Role: "+data.Role;
                document.getElementById("program").innerHTML="Program: "+data.Program;
                document.getElementById("dur").innerHTML="Duration: "+data.Duration;
                document.getElementById("cert").innerHTML="Certificate No: "+BadgeNo;
                document.getElementById("ver").innerHTML="Status: Verified" ;
                // console.log(data);
            }
            else{
                setError('Badge Number '+BadgeNo+' is invalid');
                setTimeout(function(){ 
                    setError('');
                 }, 2000);
            }

            
            });
            setBadge("")   

    }

   


    return(
        <>
           <div className="container text-center">

           <img src={defaultBadge} className="img-fluid " />

               <p className="display-6"><i className="fa fa-id-badge" aria-hidden="true"> Verify Badge </i></p>
            <form onSubmit={VerifyBadge}>

            {message && <div className="alert alert-success" role="alert">
                {message}
            </div>}
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}

            <div className="row g-2">
                <div className="">
                <div className="form-floating">
                    <input className="form-control no-border" placeholder="Leave a comment here" id="floatingTextarea" value={BadgeNo} onClick={()=>setShow(false)} onChange={(e)=> setBadge(e.target.value)}  required></input>
                    <label htmlFor="floatingInput">Enter Your Badge Number</label>
                </div>
                </div>
                <div>
                    <button  type="submit" className="btn cbtn">Submit</button>
                    {show ? <Certificate /> : null}
                </div>
             </div>
             </form>
            </div>

        </>
    );
    
}

const Certificate = () => <div>


    <div className="card shadow mt-4">
            <div className="card-body shadow" style={{textAlign:"left"}}>
                <h5 className="card-title" id="name"> </h5>
                <h6 className="card-subtitle mb-2" id="role"></h6>
                <h6 className="card-subtitle mb-2" id="program"></h6>
                <h6 className="card-subtitle mb-2" id="dur"></h6>
                <h6 className="card-subtitle mb-2" id="cert"></h6>
                <h6 className="card-subtitle mb-2" style={{color:"green"}} id="ver"></h6>
            </div>
    </div>




    {/*    */}
{/*    <a className="cbtn btn" id="Download" >Donwload</a>*/}
{/*  <div id="startBadge" className="Gen-Badge d-flex justify-content-center ">*/}

{/*        <div className="card badge-card shadow" id="pdfdoc">*/}
{/*            <div className="card-body b-body text-center">*/}
{/*            <img src={tbadge} className="img-fluid" alt="Internship completion Badge | cwipedia"/>*/}
{/*                <p className="display-5">This is awarded to</p>*/}
{/*                <p className="h2" id="getName"></p>*/}
{/*                */}
{/*                <p className="h6" id="role"></p>*/}
{/*                <p id="award"></p>*/}

{/*                <p id="learn"></p>*/}

{/*                <div className="row gy-5">*/}
{/*                    <div className="col-6">*/}
{/*                        <hr/>*/}
{/*                        <p id="badgenum"></p>*/}
{/*                        <p><i class="fa fa-check" aria-hidden="true"></i> Verified at verify.cwipedia.in</p>*/}
{/*                    </div>*/}
{/*                    <div className="col-6">*/}
{/*                        <hr/>*/}
{/*            <img src={sign} className="img-fluid" alt="Instructor" />*/}
{/*                        <h6>Shivam Hande</h6>*/}
{/*                        <p>Instructor</p>*/}
{/*                    </div>*/}
{/*                </div>*/}

{/*            </div>*/}
{/*        </div>*/}
{/*</div>*/}



</div>;

export default Header;